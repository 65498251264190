import { CommonCenterResponse } from '@api/services/common/center/common-center.dto'
import { CommonJobResponse } from '@api/services/common/job/common-job.dto'
import { CommonLaboratoryResponse } from '@api/services/common/common.dto'
import { OperationResponse } from '@api/services/configuration/operation/configuration-operation.dto'
import { CommonResponsibilityResponse } from '@api/services/common/responsibility/common-responsibility.dto'
import { CommonDepartmentResponse } from '@api/services/common/department/common-department.dto'
import { ConfigurationPersonnelResponse } from '@api/services/configuration/personnel/configuration-personnel.dto'
import { CommonLanguageResponse } from '@api/services/common/language/common-language.dto'
import { Nullable, StrictNullable } from '@/app/core/types/app-types'

export enum IdentifierTypeScopeEnum {
    PERSON = 'PERSON',
    CENTER_PATIENT = 'CENTER_PATIENT',
    REQUEST = 'REQUEST',
    SUBREQUEST_CODE = 'SUBREQUEST_CODE',
    SUBREQUEST = 'SUBREQUEST',
    CASE = 'CASE',
    SAMPLE = 'SAMPLE',
    SUBSAMPLE = 'SUBSAMPLE',
    SAMPLE_NUMBER = 'SAMPLE_NUMBER',
    SUBSAMPLE_NUMBER = 'SUBSAMPLE_NUMBER',
    PORTION = 'PORTION',
    PORTION_NUMBER = 'PORTION_NUMBER',
    SUBPORTION = 'SUBPORTION',
    SUBPORTION_NUMBER = 'SUBPORTION_NUMBER',
    INCIDENT = 'INCIDENT',
    SHIPMENT = 'SHIPMENT'
}

export enum UserType {
    PETITIONER = 'PETITIONER',
    PERSONNEL = 'PERSONNEL',
    PATIENT = 'PATIENT'
}

// Object that represents a identifier type
export interface IdentifierTypeResponse {
    code: string // Identifier type code
    id: number // IdentifierType Id
    scope: IdentifierTypeScopeEnum // identifier type scope
    name: string // Translated name
}

// Object that represents compact info about a laboratory
export interface LaboratoryIndexResponse {
    labExternalId: string // Laboratory externalId
    labId: number // Laboratory identifier
    labName: string // Laboratory name
}

// Object that contains a regular expression that validates an identifier type
export interface IdentifierRegexResponse {
    id: number // IdentifierRegex Id
    identifierType: IdentifierTypeResponse
    laboratory: StrictNullable<LaboratoryIndexResponse>
    regex: string // regular expression that validates the identifier type
}

///

// Collection of global parameters needed by the front-end
export interface FrontGlobalParametersDetailResponse {
    bubbleRefreshPeriod: number // bubble refresh period in seconds
    demoEnvironment: boolean // whether the environment is a demo
    mainPatientIdentifierType: IdentifierTypeResponse
    middleNameSupported: boolean // whether patient’s middle name is supported
    passwordMaximumLength: number // password maximum length
    passwordMinimumLength: number // password minimum length
    releaseVersion: string // Application release version
    secondSurnameSupported: boolean // whether patient’s second surname is supported
    secondaryPatientIdentifierType: IdentifierTypeResponse
}

///

// Object that represents a laboratory parameters module group active status
export interface ModuleStatusDetailResponse {
    moduleCode: string // Parameter module code
    activeStatus: boolean // Parameter module active status
}

// This object represents an instance of a Parameter Config.It usually contains a possible classification of a parameter.
export interface ParameterConfigResponse {
    id: number // Parameter config Id
    label: string // Parameter config label
    translated: string // the label string related has translation
    value: string // Parameter config value
}

// This object represents a parameter type
export interface ParameterTypeResponse {
    id: number // Parameter type Id
    value: string // Parameter type value
}

// This object represents a parameter group
export interface ParameterGroupResponse {
    code: string // Parameter group code
    id: number // Parameter group Id
    scope: 'G' | 'U' | 'L' // Parameter group scope. Valid scopes are G (for global parameters), U (for userapp parameters), L (for laboratory parameters)
}

// This object represents a parameter
export interface ParameterResponse {
    code: string // Parameter code
    id: number // Parameter Id
    parameterConfigs: ParameterConfigResponse[] // Parameter’s additional configuration
    parameterGroup: ParameterGroupResponse
    parameterType: ParameterTypeResponse
}

// This object represents an instance of a laboratory parameter
export interface LaboratoryParameterResponse {
    id: number // Laboratory parameter Id
    laboratoryLabId: number // Laboratory identifier
    parameter: ParameterResponse
    value: string // Parameter value
}

// Object that represents a module
export interface ModuleDetailResponse {
    moduleCode: string // Module code
}

// This object represents the configuration for accessing and representing the user interface for a given laboratory for a given user
export interface LaboratoryConfigurationResponse {
    activeModules: ModuleStatusDetailResponse[] // Status modules by laboratory
    center: CommonCenterResponse
    frontLaboratoryParameters: LaboratoryParameterResponse[] // Front laboratory parameters
    jobs: CommonJobResponse[] // Personnel’s jobs in the laboratory
    laboratory: CommonLaboratoryResponse
    modules: ModuleDetailResponse[] // List of modules available for the laboratory
    operations: OperationResponse[] // List of operations for the laboratory
    petitionerModuleActive: boolean // True if petitioner module is active for the laboratory
    responsibilities: CommonResponsibilityResponse[] // List of responsibilities for the laboratory
}

///

// Object containing Petitioner information for auth
export interface AuthPetitionerResponse {
    id: number // Petitioner identifier
    active: boolean // True if the staff member is active; false otherwise
    code: string // Code/External Id
    departments: CommonDepartmentResponse[] // Departments the petitioner is associated to
    isPhysician: boolean // true if the petitioner is a physician; false otherwise
    name: string // Name of the staff member
    middleName: string // Middle name of the staff member
    surname1: string // First surname
    surname2: string // Second surname
    fullName: string // Full name
}

// Object that represents the information of a user
export interface UserInformationDetailResponse {
    id: number // user internal id
    userType: UserType
    penultimateAccess: string // last login date
    personnel: ConfigurationPersonnelResponse
    petitioner: AuthPetitionerResponse
    printers: { [key: string]: string } // printers
    printersEncoded: { [key: string]: string } // printers encoders
    reportingLanguage: CommonLanguageResponse
    uiLanguage: CommonLanguageResponse
    username: string // username
}

// DTO for optionally updating certain userapp and personnel fields
export interface UserappPatchRequest {
    reportingLanguageId?: Nullable<number> // User configured default language
    uiLanguageId?: Nullable<number> // User configured default language

    // ONLY FOR PERSONNEL: True if personnel allows validation by users with global validation of entities where they are the assigned responsible; false otherwise
    personnelAllowsValidationByOtherUsers?: Nullable<boolean>
}
