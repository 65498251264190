import { computed, defineComponent } from 'vue'
import AnapathTypeLogo from '@/app/shared/components/anapath-type-logo/anapath-type-logo.vue'
import version from '@/../version.json'
import moment from 'moment'

export default defineComponent({
    name: 'LoginMenu',
    components: { AnapathTypeLogo },
    setup() {
        return {
            /// COMPUTED
            version: computed(() => version.version),
            currentYear: computed(() => moment().format('YYYY'))
        }
    }
})
