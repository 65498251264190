import { EmitFn, store } from '@api/lib'
import {
    IdentifierRegexResponse,
    IdentifierTypeScopeEnum
} from '@api/services/common/initialization/common-initialization.dto'
import { StrictNullable } from '@/app/core/types/app-types'
import {
    SearchData,
    SearcherEmitEvent,
    SearcherEvent,
    SearcherParams,
    SearchInformation,
    SearchInformationEvent
} from '@/app/shared/components/searcher/searcher-model'
import { ToastUtil } from '@/app/core/utils/lib/overlay/toast-util'
import { isEqual } from 'lodash'
import moment from 'moment'

export class SearcherClass {
    private readonly _emit: EmitFn<SearcherEmitEvent>

    private _searched: boolean
    private _lastSearch: StrictNullable<SearchData>

    public params: SearcherParams
    public search: string
    public visibleCodeReader: boolean

    private static get _identifierRegexes(): IdentifierRegexResponse[] {
        return store.state.identifierRegexes
    }
    private static get _timeBetweenSearchesMillis(): number {
        return ToastUtil.toastDuration
    }

    constructor(emit: EmitFn<SearcherEmitEvent>, params: SearcherParams) {
        this._emit = emit
        this.params = params

        this._searched = false
        this._lastSearch = null

        this.search = ''
        this.visibleCodeReader = false
    }

    public select(): void {
        const focus = document.activeElement as HTMLInputElement
        focus?.select()
    }

    public clearSearch(): void {
        this.search = ''
        this._searched = false
        this._emit(SearcherEvent.CLEAR)
    }

    private _checkAlreadySearched(searchEvent: SearchInformationEvent, searchInformation: SearchInformation): boolean {
        if (
            this._lastSearch &&
            this._lastSearch.searchEvent === searchEvent &&
            isEqual(this._lastSearch.searchInformation, searchInformation)
        ) {
            return moment().diff(this._lastSearch.timestamp) < SearcherClass._timeBetweenSearchesMillis
        }
        return false
    }
    private _searchFound(searchEvent: SearchInformationEvent, searchInformation: SearchInformation) {
        if (!this._checkAlreadySearched(searchEvent, searchInformation)) {
            this._emit(searchEvent, searchInformation)

            this._lastSearch = {
                searchEvent,
                searchInformation,
                timestamp: new Date()
            }
        }

        if (this.params.resetAfterSearch) {
            this.clearSearch()
        }

        if (this.params.closeAfterSearch) {
            this.hideCodeReader()
        }
    }

    private _getIdentifierTypeScopes(): IdentifierTypeScopeEnum[] {
        const identifierTypeScopes: IdentifierTypeScopeEnum[] = []
        if (this.params.caseSearching) {
            identifierTypeScopes.push(IdentifierTypeScopeEnum.CASE)
        }
        if (this.params.patientSearching) {
            identifierTypeScopes.push(IdentifierTypeScopeEnum.PERSON)
            identifierTypeScopes.push(IdentifierTypeScopeEnum.CENTER_PATIENT)
        }
        if (this.params.requestSearching) {
            identifierTypeScopes.push(IdentifierTypeScopeEnum.REQUEST)
        }
        if (this.params.subrequestSearching) {
            identifierTypeScopes.push(IdentifierTypeScopeEnum.SUBREQUEST)
        }
        if (this.params.sampleSearching) {
            identifierTypeScopes.push(IdentifierTypeScopeEnum.SAMPLE)
        }
        if (this.params.portionSearching) {
            identifierTypeScopes.push(IdentifierTypeScopeEnum.PORTION)
        }
        if (this.params.subportionSearching) {
            identifierTypeScopes.push(IdentifierTypeScopeEnum.SUBPORTION)
        }
        return identifierTypeScopes
    }

    private static _getSearchInformationEvent(identifierTypeScope: IdentifierTypeScopeEnum): StrictNullable<SearchInformationEvent> {
        switch (identifierTypeScope) {
        case IdentifierTypeScopeEnum.CASE:
            return SearchInformationEvent.STUDY
        case IdentifierTypeScopeEnum.PERSON:
            return SearchInformationEvent.PATIENT_NIF
        case IdentifierTypeScopeEnum.CENTER_PATIENT:
            return SearchInformationEvent.PATIENT_NHC
        case IdentifierTypeScopeEnum.REQUEST:
            return SearchInformationEvent.REQUEST
        case IdentifierTypeScopeEnum.SUBREQUEST:
            return SearchInformationEvent.SUBREQUEST
        case IdentifierTypeScopeEnum.SAMPLE:
            return SearchInformationEvent.SAMPLE
        case IdentifierTypeScopeEnum.PORTION:
            return SearchInformationEvent.PORTION
        case IdentifierTypeScopeEnum.SUBPORTION:
            return SearchInformationEvent.SUBPORTION
        case IdentifierTypeScopeEnum.INCIDENT:
            return SearchInformationEvent.INCIDENT
        case IdentifierTypeScopeEnum.SHIPMENT:
            return SearchInformationEvent.SHIPMENT
        default:
            return null
        }
    }

    public async doSearch(search: string = this.search): Promise<void> {
        this.search = search.trim()
        this._searched = true

        if (this.params.locationSearching) {
            const searchInformation: SearchInformation = {
                identifierType: 'identifierType.location',
                search
            }
            this._searchFound(SearchInformationEvent.LOCATION, searchInformation)
            return
        }

        const identifierRegexes =
            SearcherClass._identifierRegexes.filter(
                identifierRegex => this._getIdentifierTypeScopes().includes(identifierRegex.identifierType.scope))
        if (identifierRegexes.length) {
            for (const identifierRegex of identifierRegexes) {
                const regexExp = new RegExp(`^(?:${identifierRegex.regex})$`)
                if (regexExp.test(search)) {
                    const searchEvent = SearcherClass._getSearchInformationEvent(identifierRegex.identifierType.scope)
                    if (searchEvent) {
                        const searchInformation: SearchInformation = {
                            identifierType: identifierRegex.identifierType.code,
                            search
                        }
                        this._searchFound(searchEvent, searchInformation)
                        return
                    }
                }
            }
        }

        await ToastUtil.showError(this.params.errorMessage)
        this.select()
    }

    public async searchOnBlur(): Promise<void> {
        if (this.params.searchOnBlur && !this.visibleCodeReader) {
            await this.doSearch(this.search)
        }
    }

    public showCodeReader(): void {
        this.visibleCodeReader = true
    }
    public hideCodeReader(): void {
        this.visibleCodeReader = false
    }
}
