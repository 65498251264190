export enum SampleOperation {
    ARCHIVE = 'operation.entity.sample.archive',
    ATTACH = 'operation.entity.sample.attach',
    CREATE = 'operation.entity.sample.create',
    DELETE = 'operation.entity.sample.delete',
    DISCARD = 'operation.entity.sample.discard',
    DO_SAMPLING = 'operation.entity.sample.do-sampling',
    EDIT = 'operation.entity.sample.edit',
    FROM_PROCESSOR = 'operation.entity.sample.from-processor',
    PROCESS = 'operation.entity.sample.process',
    REPROCESS = 'operation.entity.sample.reprocess',
    REQUEST_REPROCESS = 'operation.entity.sample.request-reprocess',
    REQUEST_SAMPLING = 'operation.entity.sample.request-sampling',
    TAKE_OUT_FROM_LABORATORY = 'operation.entity.sample.take-out-from-laboratory',
    TO_PROCESSOR = 'operation.entity.sample.to-processor',
    TRANSCRIBE_MACRO = 'operation.entity.sample.transcribe-macro',
    UNARCHIVE_TO_DISCARD = 'operation.entity.sample.unarchive-to-discard',
    UNARCHIVE_TO_REPROCESS = 'operation.entity.sample.unarchive-to-reprocess',
    UNARCHIVE_TO_SAMPLING = 'operation.entity.sample.unarchive-to-sampling',
    VIEW_ALL_PROCESS = 'operation.entity.sample.viewallprocess'
}
