import { StrictNullable } from '@/app/core/types/app-types'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { store } from '@api/lib'
import { commonUserappProfileImageService } from '@api/services/common/userapp-profile-image/common-userapp-profile-image.service'
import { CancelToken } from 'axios'

interface AnapathWindow extends Window {
    __env: {
        backendHost: string
        downloadsURL: string
    }
}

export class HelperUtil {
    public static get apiUrl(): string {
        const w = window as unknown as AnapathWindow
        return w.__env ? w.__env.backendHost + 'anapath/core/api' : 'imOnATest!'
    }
    public static get viewerApiUrl(): string {
        const w = window as unknown as AnapathWindow
        return w.__env ? w.__env.backendHost + 'anapath/viewer/api' : 'imOnATest!'
    }
    public static get webSocketUrl(): string {
        const w = window as unknown as AnapathWindow
        return w.__env ? w.__env.backendHost + 'anapath/core/websocket' : 'imOnATest!'
    }
    public static get downloadsUrl(): string {
        const w = window as unknown as AnapathWindow
        return w.__env ? w.__env.downloadsURL : 'imOnATest!'
    }
    public static get anapathPrinterInstallerUrl(): string {
        return `${this.downloadsUrl}/AnapathPrinterInstaller.msi`
    }

    private static _getSummaryRefreshPeriod(): number {
        const bubbleRefreshPeriod = store.state.parameters.global?.bubbleRefreshPeriod
        if (bubbleRefreshPeriod &&
            (bubbleRefreshPeriod === 0 || bubbleRefreshPeriod > 60)) {
            return bubbleRefreshPeriod
        }
        return 60
    }
    public static setSummaryInterval(handler: Function): number | void {
        const timeoutInSeconds = HelperUtil._getSummaryRefreshPeriod()
        if (timeoutInSeconds > 0) {
            return setInterval(
                handler,
                timeoutInSeconds * 1000)
        } else {
            handler()
        }
    }

    public static async fileToBase64(blob: Blob): Promise<StrictNullable<string | ArrayBuffer>> {
        if (blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(blob)
                reader.onload = () => resolve(reader.result)
                reader.onerror = error => reject(error)
            })
        }
        return null
    }

    public static async getProfileImage(personnelId: number, cancelToken?: CancelToken): Promise<StrictNullable<string | ArrayBuffer>> {
        const response = await PromiseUtil.toCallSecure(
            commonUserappProfileImageService.getProfileImage(personnelId, cancelToken),
            error => PromiseUtil.handleAxiosError(error, [404]))
        if (response) {
            return this.fileToBase64(response)
        }
        return null
    }
}
