import { Optional, StrictNullable } from '@/app/core/types/app-types'
import { PromiseUtil } from '@/app/core/utils/promise-util'
import { AuthState } from '@/app/plugins/vuex/store/root/modules/session/session-store-state.model'
import { i18n, store } from '@api/lib'
import { sessionStore } from '@api/session-store'
import { authService, GlobalExternalAuthenticationResponse, UserTokenResponse } from '@vitro/anapath-frontend-library-api'
import moment from 'moment'
import { userLoggedResetState } from '@/app/plugins/vuex/store/root/plugins/user-logged-in-changed-store-plugin'

const TWO_MINUTES_IN_SECONDS = 120
const VISIBLE_WIZARD_KEY_PREFIX = 'visibleWizard'

class Auth {
    private _refreshTokenTimeout: Optional<number>
    private _userDowntimeTimeout: Optional<number>

    public get visibleWizard(): boolean {
        const visibleWizard = localStorage.getItem(`${VISIBLE_WIZARD_KEY_PREFIX}-${store.state.userInformation?.id}`)
        if (visibleWizard !== null) {
            return Boolean(JSON.parse(visibleWizard))
        }
        return true
    }
    public set visibleWizard(visibleWizard: boolean) {
        localStorage.setItem(`${VISIBLE_WIZARD_KEY_PREFIX}-${store.state.userInformation?.id}`, `${visibleWizard}`)
    }

    public get state(): StrictNullable<AuthState> {
        return store.getters['session/auth']
    }

    private _cancelRefreshTokenTimeout(): void {
        if (this._refreshTokenTimeout) {
            clearTimeout(this._refreshTokenTimeout)
        }
    }
    public resetRefreshTokenTimeout(): void {
        this._cancelRefreshTokenTimeout()

        if (store.state.userLoggedIn && this.state && typeof this.state.expiresIn === 'number') {
            const timeout = (this.state.expiresIn - TWO_MINUTES_IN_SECONDS) * 1000
            this._refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout)
        }
    }

    private _cancelUserDowntimeTimeout(): void {
        if (this._userDowntimeTimeout) {
            clearTimeout(this._userDowntimeTimeout)
        }
    }
    public async resetUserDowntimeTimeout(): Promise<void> {
        this._cancelUserDowntimeTimeout()
        await store.dispatch('changeShowUserDowntimeModal', false)

        if (store.state.userLoggedIn && this.state && typeof this.state.userDowntimeInSeconds === 'number' && typeof this.state.countdownToCloseSessionInSeconds === 'number') {
            const downtimeDateLimit = moment().add(this.state.userDowntimeInSeconds, 'seconds').format()
            await store.dispatch('session/changeAuth', { ...this.state, downtimeDateLimit })

            const timeout = (this.state.userDowntimeInSeconds - this.state.countdownToCloseSessionInSeconds) * 1000
            this._userDowntimeTimeout = setTimeout(Auth._showCountdownModal, timeout)
        }
    }

    public async logIn(username: string, password: string): Promise<void> {
        if (this.state?.accessToken) {
            await userLoggedResetState(store)
        }

        const userToken = await authService.login({ username, password })
        await this.updateAuthData(userToken)

        this.resetRefreshTokenTimeout()
        await this.resetUserDowntimeTimeout()
    }
    public async logOut(): Promise<void> {
        await store.dispatch('changeUserLoggedIn', false)
    }

    public async isGlobalExternalAuthentication(): Promise<StrictNullable<GlobalExternalAuthenticationResponse>> {
        return PromiseUtil.toCallSecure(
            authService.isGlobalExternalAuthentication(i18n.locale.value))
    }

    private static async _clearTokenData(): Promise<void> {
        await store.dispatch('session/changeAuth', null)
        sessionStore.reset()
    }

    public async clearSession(): Promise<void> {
        this._cancelRefreshTokenTimeout()
        this._cancelUserDowntimeTimeout()

        await store.dispatch('session/changeLabId', null)
        await store.dispatch('changeShowUserDowntimeModal', false)

        await Auth._clearTokenData()
    }

    private static async _updateSession(): Promise<void> {
        try {
            await sessionStore.update()
        } catch (e) {
            await Auth._clearTokenData()
            throw e
        }
    }

    public async updateAuthData(userToken: UserTokenResponse): Promise<void> {
        if (!store.state.userLoggedIn) {
            await store.dispatch('changeUserLoggedIn', true)
        }
        await store.dispatch('session/changeAuth', userToken)

        await Auth._updateSession()
    }

    public async refreshToken(token?: string): Promise<void> {
        try {
            if (token) {
                await store.dispatch('session/changeAuth', { ...this.state, accessToken: token })
            }
            const data = await authService.refresh()
            await this.updateAuthData(data)
            this.resetRefreshTokenTimeout()
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error refreshing token. ¡Forced logout!', e)
            await this.logOut()
        }
    }

    private static async _showCountdownModal(): Promise<void> {
        if (store.state.userLoggedIn) {
            await store.dispatch('changeShowUserDowntimeModal', true)
        }
    }
}
export const auth = new Auth()
